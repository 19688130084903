import "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import * as bootstrap from "bootstrap"

import "./controllers"

document.addEventListener("turbo:load", () => {
  const sidebarContainer = document.querySelector('.wrapper')
  const sidebarToggler = document.querySelector('.sidebar-toggler')
  sidebarToggler.addEventListener('click', (event) => {
    if (sidebarContainer.classList.contains('sidebar-collapsed')) {
      sidebarContainer.classList.remove('sidebar-collapsed')
    } else {
      sidebarContainer.classList.add('sidebar-collapsed')
    }
  })
})

Rails.start()
